import React, { useEffect, useState,useRef } from 'react';
import {BrowserMultiFormatReader} from '@zxing/browser';
import send_data from './lib';


function QrCodeScanner() {
 
  const [cu_result,set_cu_result]=useState('');  //序列号
  const [show_status,set_show_status]=useState(false);  

  const [stock_type,set_stock_type]=useState([]);
  const [stock_mold,set_stock_mold]=useState([]);
  const [stock_owner,set_stock_owner]=useState([]);


  const [cu_stock_type,set_cu_stock_type]=useState('');//类型
  const [cu_stock_mold,set_cu_stock_mold]=useState('');//数量
  const [cu_stock_bianhao,set_cu_stock_bianhao]=useState('');//编号
  const [cu_stock_louceng,set_cu_stock_louceng]=useState('');//楼层
  const [cu_stock_kucun,set_cu_stock_kucun]=useState('');//库存位置
  const [cu_stock_number,set_cu_stock_number]=useState('');//数量
  const [cu_stock_owner,set_cu_stock_owner]=useState('');
  const [cu_stock_status,set_cu_stock_status]=useState('下架');
  const [cu_password,set_cu_password]=useState('');

  const generateRandomNumber = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
    
    return year+month+day+hours+minutes+seconds+randomNumber;
  };

  function Scan_box()
  {
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const codeReader = new BrowserMultiFormatReader ();
    function do_scan()
    {  
      codeReader.decodeFromVideoElement(videoRef.current, (result, error) => {
        if (result) {
          set_cu_result(result.text); 
          set_show_status(false);     
          alert(result.text)  
        } 
      });

    }  
    useEffect(()=>{
      do_scan();
    })
    useEffect(() => {    
      const constraints = { video: { facingMode: 'environment' } };
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          setStream(stream);
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
  
      return () => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      };
    }, []);
    return(
      <div className='scan_box'>
        <video className='video' ref={videoRef} autoPlay playsInline />
      </div>
    )
  }


  useEffect(()=>{


    set_cu_stock_bianhao(generateRandomNumber);
    let par={"api":"get_stock_type"};

    send_data(par,(e)=>{
      
      set_stock_type(e.data.stock_type);
      set_stock_owner(e.data.owner);
      set_cu_stock_type(e.data.stock_type[0]);
      set_cu_stock_owner(e.data.owner[0]);
    });
    send_data({"api":"get_stock_mold","mold":"服务器"},(e)=>{
      if(e.data!=null)
      {
          set_stock_mold(e.data.stock_mold);
          set_cu_stock_mold(e.data.stock_mold[0]);
      }
      
    });

  },[]);

  function hande_click_type(e)
  {
    set_cu_stock_type(e.target.value);
    set_stock_mold([]);
    let par={"api":"get_stock_mold","mold":e.target.value}
    send_data(par,(e)=>{
      if(e.data!=null)
     {
        set_stock_mold(e.data.stock_mold);
        set_cu_stock_mold(e.data.stock_mold[0]);
     } 

      
    });
  }

  function do_send()
  {
    let par={
      "api":"save_stock",
      "类型":cu_stock_type,
      "型号":cu_stock_mold,
      "编号":cu_stock_bianhao,
      "序列号":cu_result,
      "所有者":cu_stock_owner,
      "状态":cu_stock_status,
      "数量":cu_stock_number,
      "楼层":cu_stock_louceng,
      "库存位置":cu_stock_kucun,
      "cu_password":cu_password
    }
    send_data(par,(e)=>{
      if(e.status=='添加成功')
      {
        set_cu_result('');
        set_cu_stock_bianhao('');
      }
      
      alert(e.status);

    });

  }

  return (
    <div>
      
      {show_status==true?<Scan_box />:
      <>
        <span className='headtitle'>安易库存录入终端</span>
      <div className='input_line'>
          <span>类型：</span>
          <select value={cu_stock_type} onChange={(e)=>{hande_click_type(e)}}>
            {
              stock_type.map((key,index)=>{
                return(
                  <option key={index} value={key}>{key}</option>
                )
              })
            }
           
          </select>
      </div>

      <div className='input_line'>
          <span>型号：</span>
          <select value={cu_stock_mold} onChange={(e)=>set_cu_stock_mold(e.target.value)}>
            {
              stock_mold.map((key,index)=>{
                return(
                  <option value={key}>{key}</option>
                )
              })
            }
           
          </select>
      </div>

      <div className='input_line'>
          <span>编号：</span>
          <input value={cu_stock_bianhao} onChange={(e)=>{set_cu_stock_bianhao(e.target.value)}} />
      </div>
      <div className='input_line'>
          <span>序列号：</span>
          <input value={cu_result} onChange={(e)=>set_cu_result(e.target.value)} />
          <div onClick={()=>set_show_status(true)} className='scan_btn'>扫描</div>
      </div>
      <div className='input_line'>
          <span>楼层：</span>
          <input value={cu_stock_louceng} type="tel" onChange={(e)=>set_cu_stock_louceng(e.target.value)} />
      </div>
      <div className='input_line'>
          <span>库存位置：</span>
          <input value={cu_stock_kucun} onChange={(e)=>set_cu_stock_kucun(e.target.value)} />
      </div>
      <div className='input_line'>
          <span>数量：</span>
          <input value={cu_stock_number} type="tel" onChange={(e)=>{set_cu_stock_number(e.target.value)}} />
      </div>
      <div className='input_line'>
          <span>所有者：</span>
          <select value={cu_stock_owner} onChange={(e)=>set_cu_stock_owner(e.target.value)}>
            {
              stock_owner.map((key,index)=>{
                return(
                  <option value={key}>{key}</option>
                )
              })
            }
           
          </select>
        
      </div>

      <div className='input_line'>
          <span>状态：</span>
          <select value={cu_stock_status} onChange={(e)=>set_cu_stock_status(e.target.value)}>
              <option value="下架">下架</option>
              <option value="上架">上架</option>
          </select>
        
      </div>
      <div className='input_line'>
          <span>操作密码：</span>
          <input value={cu_password} type="password" onChange={(e)=>{set_cu_password(e.target.value)}} />
      </div>
      <div onClick={(e)=>do_send()} className='save_btn'>提交</div>
      </>
      }      
      
    </div>
  );
}

export default QrCodeScanner;